(function () {

  // var inputCheckbox = document.createElement('input');
  // inputCheckbox.className = 'hidden mobile-menu';
  // inputCheckbox.id = 'mobile-menu';
  // inputCheckbox.setAttribute('type', 'checkbox');
  let navMobile = document.querySelector('.mobile-nav');
  // navMobile.parentNode.insertBefore(inputCheckbox, navMobile);

  let newNav = document.createElement('nav');
  // newWrapBlock = document.createElement('div');

  let nav = document.querySelector('.js-menu');
  // langMenu = document.querySelector('.lang-list'),
  // wrapBlock = document.querySelector('.head-icon-wrap'),
  // mobileMenu = document.querySelector('.mobile-toggle');
  // mobileLogo = document.querySelector('.mobile-top-logo');

  newNav = nav.cloneNode(true);
  // newLangMenu = langMenu.cloneNode(true);
  // newWrapBlock = wrapBlock.cloneNode(true);

  // newLangMenu.className += ' hide-lg';
  newNav.querySelector('.js-logo').classList.remove('js-logo');

  navMobile.appendChild(newNav);
  navMobile.querySelectorAll('.top-menu__item').forEach(function (element) {
    element.prepend(document.createElement('span'));
    element.querySelector('span').classList.add('mobile-icon-arrow');
  })

// mobileLogo.insertAdjacentElement('afterend', newLangMenu);

  // mobileMenu.parentNode.appendChild(newWrapBlock);

  // newWrapBlock.classList.remove("js-pandora-box");

  function addMobileClasses(arToAddClass, searchedElement, nameClassAdded) {
    arToAddClass.querySelectorAll(searchedElement).forEach(function (element, index) {
      element.classList.add(nameClassAdded);
    });
  }

  addMobileClasses(navMobile, '.top-menu__item', 'js-parent-mobile');
  addMobileClasses(navMobile, '.mobile-icon-arrow', 'js-hand-mobile');
  addMobileClasses(navMobile, '.top-menu-modal', 'js-box-mobile');
  // addMobileClasses(newNav, '.nav-sub__item', 'js-parent-mobile-sub');
  // addMobileClasses(newNav, '.mobile-open-sub', 'js-hand-mobile-sub');
  // addMobileClasses(newNav, '.mobile-menu-wrap', 'js-box-mobile-sub');
// console.log(newNav.querySelectorAll('.nav__item'));
// newNav.querySelectorAll('.nav__item').classList.add('js-parent-mobile');

})();

var safari = false;
var ua = navigator.userAgent.toLowerCase();
if (ua.indexOf('safari') != -1) {
  if (ua.indexOf('chrome') > -1) {
    safari = false;
  } else {
    safari = true;
    // alert(safari) // Safari
  }
}

jQuery(document).ready(function () {

  if (safari) {
    $('body').addClass("safari-browser");
  }

  if ($('.js-parent-mobile').length) {
    wiyzjalka($('.js-hand-mobile'), '.js-parent-mobile', '.js-box-mobile', "normal", false, 0)
  }

  if ($('.js-file-add').length) {
    for (let i = document.querySelectorAll('.js-file-add').length - 1; i >= 0; i--) {
      let elm = document.querySelectorAll('.js-file-add')[i].querySelector('[type="file"]');
      setFileNameInput(document.querySelectorAll('.js-file-add')[i]);
      fileCheck(elm);
    }
  }
  if ($('.js-modal-menu').length) {
    for (var i = document.querySelectorAll('.js-modal-menu').length - 1; i >= 0; i--) {
      miniModal(document.querySelectorAll('.js-modal-menu')[i]);
    }
  }
  if ($('.js-modal-min').length) {
    $('.js-modal-min').on('click', function () {
      let href = this.dataset.id;
      $(href).toggleClass("open");
      $(this).toggleClass("open");
    })
  }
  if ($('.js-modal').length) {
    for (var i = document.querySelectorAll('.js-modal').length - 1; i >= 0; i--) {
      miniModal(document.querySelectorAll('.js-modal')[i]);
    }
    for (var i = document.querySelectorAll('.js-modal-close').length - 1; i >= 0; i--) {
      miniModal(document.querySelectorAll('.js-modal-close')[i]);
    }
  }

  if ($('.js-beats-hand').length) {
    wiyzjalka($('.js-beats-hand'), '.js-beats-parent', '.js-beats-box', "normal", false, 0);
  }

  if ($('.owl-carousel').length) {

    $('.owl-carousel').each(function () {
      let id = $("#" + $(this).attr("id"));
      owlA11ySet(id, false, false);
      let looop = true;
      // console.log($(this).find('.item').length,+$(this).attr('data-items'));
      if ($(this).attr('data-items') && $(this).find('.item').length < +$(this).attr('data-items')) {
        looop = ($(this).attr('data-loop')) ? true : false;
      }
      let option = ($(this).data('responsive')) ? $(this).data('responsive') : '';
      // console.log(option)

      var owl = $(this).owlCarousel({
        loop: looop,
        center: ($(this).attr('data-center')) ? true : false,
        autoWidth: ($(this).attr('data-autoWidth')) ? true : false,
        autoplay: ($(this).attr('data-autoplay')) ? true : false,
        autoplayHoverPause: true,
        autoplaySpeed: 3000,
        margin: ($(this).attr('data-margin')) ? +$(this).attr('data-margin') : 0,
        dragEndSpeed: 1000,
        dots: ($(this).attr('data-dots')) ? true : false,
        autoHeight: ($(this).attr('data-autoHeight')) ? true : false,
        nav: ($(this).attr('data-nav')) ? true : false,
        navText: (!$(this).attr('data-nav')) ? true : ['<span class="left owl-control"><svg xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="owlSvgPrev" width="19" height="14" viewBox="0 0 492 492"><title id="owlSvgPrev">Previous</title><path d="M198.608 246.104L382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z"/></svg></span>', '<span class="right owl-control"><svg xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="owlSvgNext" width="19" height="14" viewBox="0 0 492.004 492.004"><title id="owlSvgNext">Next</title><path d="M382.678 226.804L163.73 7.86C158.666 2.792 151.906 0 144.698 0s-13.968 2.792-19.032 7.86l-16.124 16.12c-10.492 10.504-10.492 27.576 0 38.064L293.398 245.9l-184.06 184.06c-5.064 5.068-7.86 11.824-7.86 19.028 0 7.212 2.796 13.968 7.86 19.04l16.124 16.116c5.068 5.068 11.824 7.86 19.032 7.86s13.968-2.792 19.032-7.86L382.678 265c5.076-5.084 7.864-11.872 7.848-19.088.016-7.244-2.772-14.028-7.848-19.108z"/></svg></span>'],
        items: ($(this).attr('data-items')) ? +$(this).attr('data-items') : 1,
        responsive: option
      });
      if ($(this).attr('data-wrap')) {
        $(this).find('.owl-nav, .owl-dots').wrapAll("<div class='owl-control-wrap'></div>");
        if ($('.js-owl-link').length) {
          // console.log($('.js-owl-link').parent().find('owl-control-wrap'));
          $('.js-owl-link').parent().find('.owl-dots').after($('.js-owl-link'));
        }
      }
    });

  }


  if ($('.js-span').length) {
    symbolWrap($('.js-span'));
  }

  if ($('.js-video-main').length) {
    $('.js-video-play').on('click', function () {
      var mediaVideo = $('#media-video').get(0);
      if (mediaVideo.paused) {
        mediaVideo.play();
        mediaVideo.setAttribute("controls", "controls");
        $('#media-video').parents(".js-video-main").addClass('active')
      } else {
        mediaVideo.pause();
        mediaVideo.removeAttribute("controls");
        $('#media-video').parents(".js-video-main").removeClass('active')
      }
    })
  }

  $(window).on('scroll', function () {

    let ps = $(this).scrollTop()

    if ($('.js-fixed-head').length) {
      if (ps >= 300) {
        $('.header').addClass('move-up')
      } else {
        $('.header').removeClass('move-up')
      }
      if (ps >= $('.js-fixed-head').offset().top) {
        $('.header').addClass('header--display');
      } else {
        if (window.matchMedia("(min-width: 480px)").matches) {
          $('.header').removeClass('header--display');
        }
      }
    }

    if (!safari) {
      waveMove($('.js-wave-top'));
      waveMove($('.js-wave-top2'));
      waveMove($('.js-wave-bottom'));
      waveMove($('.js-wave-bottom2'));
    }

    if ($('.js-wave-inner').length && !safari) {
      $('.js-wave-inner').each(function () {
        waveMove($(this));
      })
    }

    if ($('#mainSlider').length && $('#mainSlider').offset().top - $(window).height() - $(this).scrollTop() <= 0) {
      $('#mainSlider').addClass("active");
    }

  })
  $(window).trigger('scroll');

  $(".js-scroll-top").click(function () {
    $("body,html").animate({
      scrollTop: 0
    }, 800);
    return false;
  });

  $("a[data-toggle='tab']").on('show.bs.tab', function (e) {
    let id = e.currentTarget.hash.substring(1, e.currentTarget.hash.length),
      el = document.getElementById(id),
      owl = el.querySelector('.owl-carousel');
    if (owl) {
      owl.style.opacity = "0";
      owl.style.height = "0";
      setTimeout(function () {
        owl.removeAttribute("style");
      }, 600)
    }
    setTimeout(function () {
      AOS.refresh();
    }, 1000)
  });

  if ($('.js-link-to-page').length && !safari) {
    $('.js-link-to-page').on('click', function () {
      let href = $(this).children().attr("href");
      $(this).parents('.js-prod-parent').addClass('active');
      $(this).parents('.owl-item').addClass('click-active');
      $(this).addClass('active');
      setTimeout(function () {
        window.location = href;
      }, 1500);
      setTimeout(function () {
        $('body').toggleClass('body-preload')
      }, 1200);
      return false;
    })
    $('.js-link-to-page').on('click', function () {
      // console.log($(this).offset().top, ($(window).height()/2), $(this).height());
      $('body,html').animate({scrollTop: ($(this).offset().top - ($(window).height() / 2 - $(this).height() / 2))}, 'slow');
    })
  }

  if (window.matchMedia("(max-width: 480px)").matches) {
    $('.header').addClass('header--display');
  }

});

$('.logo__img').ready(function () {
  $('.logo__img').addClass('logo__img--loaded')
});

if ($('.review-list').length && (window.matchMedia("(min-width: 480px)").matches)) {
  var masonryOptions = {
    itemSelector: '.review-list__item'
  };
  var msnry = $('.review-list').masonry(masonryOptions);
}

function elementPosCalc(el, parent) {

  if (parent.length) {
    let active = parent,
      activeWidth = active.outerWidth(),
      activePos = (active.offset().left + active.outerWidth() / 2) - el.outerWidth() / 2;
    el.css({
      // "width": activeWidth + "px",
      "left": activePos + "px",
    })
  }
}

$(window).on("load", function () {

  $("body").addClass('loaded');
  setTimeout(() => {
    AOS.init({disable: "mobile"});
  }, 1000)

  $(window).on('scroll', function () {

    if ($('.js-logo-bottom').length) {
      elementPosCalc($('.js-logo-bottom'), $('.js-logo'))
    }
  })
  $(window).on('resize', function () {

    if ($('.js-logo-bottom').length) {
      elementPosCalc($('.js-logo-bottom'), $('.js-logo'))
    }

    if ($('.js-menu-active').length && !safari) {
      if ($('.js-menu').find('.active').length) {
        let active = $('.js-menu').find('.active'),
          activeWidth = active.outerWidth(),
          activePos = active.offset().left;
        $('.js-menu-active').css({
          "width": activeWidth + "px",
          "left": activePos + "px",
        })
      }
    }

    if ($('.js-menu-sub-active').length && !safari) {
      $('.js-menu-sub-active').each(function () {
        let el = $(this);
        let menu = $(this).next('.js-menu-sub').children();
        menu.each(function () {
          $(this).on('mouseover', function () {
            menu.removeClass('hover-active');
            $(this).addClass('hover-active');
            if ($(this).hasClass('hover-active')) {
              let active = $(this),
                activeWidth = active.outerWidth(),
                activePos = (active.offset().left + active.outerWidth() / 2) - el.outerWidth() / 2;
              el.css({
                // "width": activeWidth + "px",
                "transform": "translateX(" + activePos + "px)",
              })
            }
          })
        })
      })
    }

  })
  $(window).trigger('resize');

  if ($('.review-list').length && (window.matchMedia("(min-width: 480px)").matches)) {
    msnry.masonry(masonryOptions);
  }

  if ($('.liquid-button').length) {
    $('.liquid-button text').css({
      "font-family": "GillSans"
    });
  }


});